import React from 'react';
import {
  Accordion as MuiAccordion,
  lighten,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordiionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Accordion = ({ sx, ...props }) => {
  return (
    <MuiAccordion
      {...props}
      sx={{
        boxShadow: (theme) => theme.shadows[4],
        mb: '1.5rem',
        bgcolor: lighten('#3D4458', 0.95),
        '&.Mui-expanded': {
          mt: 0,
        },
        ...sx,
      }}
    />
  );
};

const AccordionSummary = ({ sx, ...props }) => (
  <MuiAccordionSummary
    {...props}
    expandIcon={<ExpandMoreIcon />}
    sx={{
      bgcolor: 'var(--gray-1)',
      '& .MuiAccordionSummary-content': {
        alignItems: 'center',
        gap: '1rem',
        color: 'var(--gray-4)',
        '& .MuiTypography-h6': {
          fontWeight: 'bold',
        },
      },
    }}
  />
);

const AccordionDetails = ({ sx, ...props }) => (
  <MuiAccordiionDetails
    {...props}
    sx={{
      '& p': {
        mt: '1.5rem',
      },
    }}
  />
);

export { Accordion, AccordionSummary, AccordionDetails };
