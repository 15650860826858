import React from 'react';

import { TextField } from '@mui/material';

let IntlCurrencyInput;

if (typeof window !== 'undefined') {
  const req = require('react-intl-currency-input');
  IntlCurrencyInput = req.default;
}

const Currency = React.forwardRef((props, ref) => {
  const { onChange, value, ...others } = props;
  const handleChange = (e, val) => {
    onChange(e, val);
  };

  if (IntlCurrencyInput)
    return (
      <IntlCurrencyInput
        {...others}
        ref={ref}
        currency="BRL"
        config={{
          locale: 'pt-BR',
          formats: {
            number: {
              BRL: {
                style: 'currency',
                currency: 'BRL',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              },
            },
          },
        }}
        defaultValue={value}
        onChange={handleChange}
      />
    );

  return <div />;
});

const CurrencyInput = (props) => (
  <TextField
    {...props}
    InputProps={{
      inputComponent: Currency,
    }}
  />
);

export { CurrencyInput };
