import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';

import { CircularProgress, Typography, Container } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import { useAuth, useUser } from '@context';
import { FullContainer, Flex } from '../layout';
import { SimpleButton } from '../form';
import { useDevice, supportUrl } from '../utils';

function Protected({ children, uri }) {
  const authCtx = useAuth();
  const userCtx = useUser();
  const { isDesktop } = useDevice();

  const [loadingUserInfo, setLoadingUserInfo] = useState(!Boolean(userCtx?.userInfo));

  useEffect(() => {
    if (!authCtx.user && !authCtx.loadingUser) {
      navigate('/login');
      return;
    }

    if (authCtx.user && !authCtx.user.emailVerified && uri !== '/unverified_email') {
      navigate('/unverified_email');
      return;
    }

    if (authCtx?.user) {
      requestUserInfo();
    }
  }, [authCtx?.user, authCtx?.loadingUser, uri]);

  const requestUserInfo = async () => {
    setLoadingUserInfo(true);

    await userCtx.getUserInfo(authCtx.user.uid);

    setLoadingUserInfo(false);
  };

  if (authCtx?.user && userCtx?.userInfo) {
    return children;
  }

  return (
    <FullContainer sx={{ backgroundColor: 'var(--gray-4)' }}>
      {loadingUserInfo ? (
        <Flex>
          <CircularProgress />
        </Flex>
      ) : (
        <Container maxWidth="md">
          <Typography variant="h5" sx={{ color: 'white', textAlign: 'center', textWrap: 'balance' }}>
            Ocorreu um erro no seu cadastro. Por favor, entre em contato com nossa equipe de suporte
          </Typography>
          <SimpleButton
            sx={{ mt: '2rem', gap: '1rem' }}
            href={supportUrl(isDesktop())}
            component="a"
            target="_blank"
            rel="noopener noreferer"
          >
            <WhatsAppIcon /> suporte
          </SimpleButton>
        </Container>
      )}
    </FullContainer>
  );
}

export { Protected };
