import React, { createContext, useContext, useState } from 'react';

const VBEContext = createContext();

function VBEProvider({ children }) {
  const [dataToVaga, setDataToVaga] = useState(null);

  const setData = (data) => {
    setDataToVaga(data);
  };
  return <VBEContext.Provider value={{ setData }}>{children}</VBEContext.Provider>;
}

export const useVBE = () => useContext(VBEContext);

export { VBEProvider };
