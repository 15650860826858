import React, { createContext, useState, useContext } from 'react';
import { Dialog, DialogContent, useTheme, useMediaQuery, DialogTitle, IconButton, Typography } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import { PageTitle } from '@components/layout';
import { Accordion, AccordionSummary, AccordionDetails } from '@components/surfaces';

import {
  TermosDeUsoPrivacidade,
  TermosDeUso,
  TermosDefinicoes,
  PoliticaDePrivacidade,
  Encerramento,
} from '@components/utils/politicasEtermos';

const TermsContext = createContext();

function TermsProvider({ children }) {
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState('');

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const closeDialog = () => {
    setOpen(false);
    setExpanded('');
  };

  const openTerms = () => {
    setOpen(true);
  };

  const handleChange = (panel) => () => {
    setExpanded((old) => (old === panel ? '' : panel));
  };
  return (
    <TermsContext.Provider value={{ openTerms }}>
      {children}
      <Dialog open={open} onClose={closeDialog} fullScreen={fullScreen} maxWidth="lg">
        <DialogTitle sx={{ display: 'flex', justifyContent: 'end' }}>
          <IconButton edge="start" color="inherit" onClick={closeDialog} aria-label="close">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <PageTitle title="Políticas e Termos" sx={{ mb: '2rem' }} />

          {[
            { title: 'Termos de Uso e Privacidade', Component: TermosDeUsoPrivacidade },
            { title: 'Termos e Definições', Component: TermosDefinicoes },
            { title: 'Termos de Uso', Component: TermosDeUso },
            { title: 'Política de Privacidade', Component: PoliticaDePrivacidade },
            { title: 'Encerramento', Component: Encerramento },
          ].map((el, index) => (
            <Accordion
              key={`${el.title}-${index}`}
              expanded={expanded === `panel-${index}`}
              onChange={handleChange(`panel-${index}`)}
            >
              <AccordionSummary>
                <Typography variant="h6" sx={{ fontWeight: 'normal' }}>
                  {el.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <el.Component />
              </AccordionDetails>
            </Accordion>
          ))}
        </DialogContent>
      </Dialog>
    </TermsContext.Provider>
  );
}

export const useTerms = () => useContext(TermsContext);

export { TermsProvider };
