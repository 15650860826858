import React from 'react';

import { StyledText } from './StyledText';

const TermosDeUsoPrivacidade = () => {
  return (
    <StyledText>
      <h1>Informações sobre como usar o "APP"</h1>

      <p>
        O “APP” é uma plataforma de gestão online vagas disponíveis na área da saúde, por meio da qual são
        disponibilizas vagas ou postos de trabalho fixos ou temporários previamente cadastradas por entidades de saúde,
        como hospitais, clínicas, postos de saúde e demais entidades ligadas à saúde.
      </p>

      <p>
        Também disponibiliza informações importantes sobre conteúdos referentes à saúde, tecnologia, educação,
        informativos do blog, plataformas e redes sociais, entre outros.{' '}
      </p>

      <p>
        O uso do “APP” não confere ao Usuário a propriedade sobre direitos de propriedade intelectual sobre os serviços
        ou sobre o conteúdo acessado.{' '}
      </p>

      <p>
        Estes termos não conferem ao Usuário o direito de usar quaisquer marcas, conteúdos ou logotipos usados no “APP”.
      </p>

      <p>Não remova, oculte ou altere quaisquer avisos legais exibidos pelo “APP”.</p>

      <p>
        O “APP” poderá enviar aos Usuários anúncios de serviços, mensagens administrativas e outras informações. Mas o
        Usuário pode desativar essas comunicações a qualquer momento.
      </p>

      <h1>DEFINIÇÕES E SIGLAS</h1>

      <p>Para os fins deste Termo de serviço, devem se considerar as seguintes definições e descrições:</p>

      <p>
        <b>Dados de Uso:</b> são dados coletados automaticamente, gerados pelo uso do “APP” ou pela própria
        infraestrutura do serviço do “APP”;
      </p>

      <p>
        <b>LGPD:</b> Lei Geral de Proteção de Dados Pessoais (LGPD), Lei nº 13.709, de 14 de agosto de 2018.
      </p>

      <p>
        <b>Titular dos Dados:</b> Pessoa natural a quem se referem os dados pessoais que são objeto de Tratamento.
      </p>

      <p>
        <b>Dado Pessoal:</b> informação relacionada a pessoa natural, direta ou indiretamente, identificada ou
        identificável.
      </p>

      <p>
        <b>Dado Pessoal Sensível:</b> dado pessoal sobre origem racial ou étnica, convicção religiosa, opinião política,
        filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à
        vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa natural.
      </p>

      <p>
        <b>Tratamento:</b> Toda operação realizada com Dados Pessoais, como coleta, registro, armazenamento, uso,
        compartilhamento, enriquecimento e eliminação.
      </p>

      <p>
        <b>Usuários:</b> Pessoas ou indivíduos que acessam ou interagem com as atividades oferecidas pelo serviço pelo
        “APP”, que são também Titulares de Dados Pessoais passíveis de Tratamento.
      </p>

      <p>
        <b>Conta de Acesso:</b> Credencial de um usuário necessária para utilizar ou acessar áreas restritas ou produtos
        e serviços do “APP”.
      </p>

      <p>
        <b>Encarregado/DPO (Data Protection Officer):</b> O responsável pelos Dados Pessoais dos Usuários na plataforma
        do “APP”, no que diz respeito à proteção da privacidade dos Usuários.
      </p>

      <p>
        <b>Controlador:</b> pessoa natural ou jurídica, de direito público ou privado, a quem competem as decisões
        referentes ao tratamento de Dados Pessoais, como: quais Dados Pessoais coletar, de quem coletar, qual é a
        finalidade do Tratamento e como é feito o tratamento. 
      </p>

      <p>
        <b>Operador:</b> é o agente responsável por realizar o Tratamento de dados em nome do Controlador e conforme a
        finalidade por este delimitada.
      </p>

      <p>
        <b>Cookies:</b> Os cookies são pequenos arquivos eletrônicos armazenados no dispositivo do usuário, que permitem
        à plataforma distinguir o visitante em um novo acesso e manter as suas preferências ao longo da respectiva
        sessão.
      </p>

      <p>
        <b>Logs:</b> Registros de atividades dos usuários efetuadas no “APP”.
      </p>

      <h1>INFORMAÇÃO DO USUÁRIO</h1>

      <p>
        O “APP” coleta, usa, divulga e processa os dados pessoais (“Dados de Usuário”) que se cadastra no
        “APP” (“Usuário”).
      </p>

      <h1>ESCOPO E APLICAÇÃO</h1>

      <p>
        Esta Política de Privacidade se aplica ao processamento de Dados do Usuário feito pelo “APP”, em todo o
        território nacional.
      </p>

      <h1>TERMOS DE USO DO “APP”</h1>

      <p>Ao usar o “APP”, você conﬁrma que leu, compreendeu e aceita os termos de uso e ﬁca a eles vinculado.</p>

      <h1>Instruções ao usuário, quanto a sua conta</h1>

      <p>
        Para fazer uso do “APP” é necessário que o Usuário crie uma conta através de cadastro de usuário na plataforma,
        sendo necessário informar, entre outros dados, endereço de e-mail e senha pessoal que será intransferível, o
        cadastro autoriza o envio de atualizações da plataforma.
      </p>

      <p>O Usuário é o único responsável, para todos os fins, pelas operações efetuadas em sua conta.</p>

      <p>Para garantir a proteção da sua conta, o usuário deve manter a senha em sigilo ou logout após cada sessão. </p>

      <p>Toda atividade realizada na conta ou por seu intermédio é de responsabilidade do Usuário.</p>

      <p>
        Não recomendamos que a senha da conta seja empregada em aplicativos de terceiros ou computadores públicos, caso
        tome conhecimento de uso não autorizado da sua senha ou conta, providencie a alteração da senha e notifique o
        setor de segurança da informação, por meio do suporte online, para as providências necessárias.
      </p>

      <h1>Responsabilidades do Usuário</h1>

      <p>
        a) Inserir apenas informações verdadeiras no sistema e se responsabilizar por suas declarações e autorizações.
      </p>

      <p>
        b) Zelar pelos dados de sua identificação digital individual sempre que acessar a Internet, informando-os apenas
        em operações em que exista a proteção de dados.
      </p>

      <p>
        c) Usar as tecnologias fornecidas pelo smartphone ou tablet para proteger a sua privacidade perante terceiros;
      </p>

      <p>
        d) Manter em sigilo as informações e mensagens recebidas em consonância com os termos de privacidade do “APP”;
      </p>

      <p>
        e) Não permitir que terceiros utilizem o “APP” no seu celular ou tablet; e cumprir rigorosamente todas as
        determinações e procedimentos previstos neste termo;
      </p>

      <p>
        f) Não enviar mensagens e conteúdo que possam ser categorizados como ilegais, imorais, degradantes,
        preconceituosos.
      </p>

      <h1>Das violações aos termos de uso</h1>

      <p>
        O Usuário pode acessar os produtos e serviços para fins lícitos, sendo este completamente responsável pelo
        conhecimento e pela adesão a qualquer e toda lei, regulamento e regras pertinentes ao uso dos produtos e
        serviços sobre os quais tratam o presente termo.
      </p>

      <p>
        Não é permitido reproduzir, distribuir, transmitir, exibir, vender, licenciar, alienar de qualquer forma ou
        explorar qualquer conteúdo acessado no “APP” para quaisquer fins, onerosos ou não.
      </p>

      <p>
        Na hipótese de qualquer medida, seja ela judicial ou não, tomada por terceiros contra o “APP”, em razão de
        qualquer conteúdo postado, publicizado ou de qualquer outra forma disponibilizado pelo Usuário do “APP”, o Grupo
        de empresas detentores dos direitos do “APP” poderá voltar tal medida contra o responsável.
      </p>

      <h1>POLÍTICA DE PRIVACIDADE</h1>

      <h3>Coleta de dados</h3>

      <p>Os dados pessoais tratados pelo “APP” são informados diretamente pelo Usuário, por meio do seu cadastro.</p>

      <p>O “APP” poderá registrar todas as atividades efetuadas pelo usuário por meio de logs.</p>

      <p>
        O “APP” coleta dados que consideramos indispensáveis para o funcionamento de nossos diversos serviços, tais
        como:
      </p>

      <ul>
        <li>Nome completo;</li>
        <li>Numero do CRM</li>
        <li>Endereço;</li>
        <li>Endereço de e-mail;</li>
        <li>Telefones;</li>
        <li>Currículos;</li>
        <li>Formação profissional;</li>
        <li>Carta de apresentação;</li>
        <li>Especialidades que atua;</li>
        <li>Especialidades pretendidas;</li>
      </ul>

      <p>Ao cadastrar-se no “APP” autoriza-nos a receber as seguintes categorias de informação de outras fontes:</p>

      <ul>
        <li>
          Onde permitido por lei, dados de checagem de antecedentes, possivelmente incluindo seus registros de
          antecedentes criminais;
        </li>
        <li>Informações sobre atuação profissional anterior.</li>
      </ul>

      <h1>USO DOS DADOS DO USUÁRIO</h1>

      <p>
        O “APP” armazenará e utilizará os seus Dados do Usuário no processo de recrutamento do Usuário para vagas
        profissionais.
      </p>

      <p>
        Todo Usuário cadastrado autoriza o uso de seus Dados do Usuário para identificarmos e entrarmos em contato a
        respeito de vagas que possam ser do seu interesse. Se não quiser que o “APP” use seus dados para esse fim, você
        poderá solicitar a exclusão dos seus Dados do Usuário.
      </p>

      <h1>COMPARTILHAMENTO DOS DADOS DE CANDIDATO</h1>

      <p>O “APP” pode compartilhar seus Dados do Usuário:</p>

      <ul>
        <li>
          com as empresas parceiras e/ou contratantes do “APP” que buscam profissionais através da disponibilização de
          vagas;
        </li>
        <li>
          com fornecedores, consultores e outros prestadores de serviços que precisem acessar essas informações para
          executar tarefas relacionadas ao processo de recrutamento em nome do Usuário;
        </li>
        <li>
          quando relacionados a, ou durante as negociações de: fusões, venda do “APP”, consolidações/reestruturações,
          financiamentos ou aquisições (integrais ou parciais) do Grupo de empresas detentoras dos direitos do “APP”;
        </li>
        <li>
          com autoridades policiais/judiciais, autoridades governamentais ou outros terceiros; se acreditarmos ser
          necessário em cumprimento com leis e regulamentos vigentes, acordos de operação, processos judiciais (como
          intimações ou mandados) ou exigências governamentais; se acreditarmos que suas ações são incoerentes com as
          nossas políticas; ou se acreditarmos que esse compartilhamento é necessário para proteger os direitos, a
          propriedade ou a segurança do “APP” ou outras partes; e
        </li>
        <li>de forma agregada e/ou anonimizada que não possa ser adequadamente utilizada para identificar você.</li>
      </ul>

      <h1>SEUS DIREITOS</h1>

      <p>Em relação ao tratamento dos seus Dados do Usuário por parte da “APP”, você tem o direito de:</p>

      <ul>
        <li>Acessar seus dados pessoais;</li>
        <li>Corrigir eventuais erros relacionados aos seus dados pessoais;</li>
        <li>Apagar ou excluir seus Dados do Usuário conforme os termos acima, e;</li>
        <li>Revogar seu consentimento ao processamento dos seus Dados do Usuário.</li>
      </ul>

      <h1>Informação sobre o compartilhamento</h1>

      <p>
        Você tem o direito de saber as entidades públicas e privadas com as quais a “APP” realiza uso compartilhado de
        dados.{' '}
      </p>
      <p>
        Manteremos, no item "Compartilhamento de dados com terceiros” desta Política, a indicação das modalidades e
        atividades que podem envolver compartilhamento de Dados com terceiros.{' '}
      </p>
      <p>
        Em todo caso, O Usuário tenha dúvidas ou quiser maiores detalhes, tem o direito de nos solicitar essas
        informações.
      </p>
      <p>
        Em alguns casos, o exercício dos seus direitos de contestar ou restringir o processamento dos seus Dados do
        Usuário, bem como de revogar o consentimento para o processamento desses dados, pode afetar a capacidade que
        temos de avaliar vagas disponíveis compatíveis com o perfil profissional do Usuário.{' '}
      </p>

      <h1>Compartilhamento de dados com terceiros</h1>

      <p>
        O “APP” poderá compartilhar dados com algumas instituições parceiras, com o propósito de viabilizar sua
        atividade comercial, otimizá-la e cumprir determinações legais, proporcionando melhores resultados aos seus
        Usuários, clientes, colaboradores e visitantes, baseado, sempre, nos princípios da necessidade, da finalidade e
        da adequação, para a realização de tais compartilhamentos.
      </p>

      <p>
        No geral, os fornecedores terceirizados usados por nós irão apenas coletar, usar e divulgar suas informações na
        medida do necessário para permitir que eles realizem os serviços que eles nos fornecem.{' '}
      </p>

      <p>
        A seguir as empresas parceiras:
        <br />
        - Grupo CMSS
        <br />
        - Executivos da Saúde
        <br />- FCM – Formação de Coordenadores Médicos
      </p>

      <h1>Banco de dados</h1>

      <p>
        Usamos como banco de dados para armazenamento o Firebase, da Google. Em sua política de privacidade igualmente,
        declara prestar suas atividades de forma adequada à legislação vigente em privacidade e proteção de dados. Os
        termos da sua política de privacidade e proteção de dados pessoais disponível em{' '}
        <a href="https://firebase.google.com/support/privacy?hl=pt-br" target="_blank" rel="noopener noreferrer">
          https://firebase.google.com/support/privacy?hl=pt-br
        </a>
        .
      </p>

      <h1>Servidores de e-mail</h1>

      <p>
        Os e-mails institucionais de empregados, Usuários e clientes bem como eventuais informações pessoais constantes
        do banco de dados correspondente ao histórico desses e-mails serão, automaticamente, compartilhados com as
        empresas proprietárias dos servidores de e-mails usados pela equipe do “APP”.
      </p>

      <p>
        Atualmente, tais fornecedoras são GOOGLE detentora do software GMAIL, que tem o acesso intermediado pela
        plataforma de integração Interceleri.{' '}
      </p>

      <p>
        Todas as empresas declaram estar com suas atividades e o tratamento de dados pessoais adequados às normas da
        LGPD, conforme as respectivas políticas, acessíveis em:
        <br /> links:
        <br />
        <a href="https://www.inteceleri.com.br/" target="_blank" rel="noopener noreferrer">
          https://www.inteceleri.com.br/
        </a>
        <br />
        <a href="https://policies.google.com/privacy?hl=pt-BR&fg=1" target="_blank" rel="noopener noreferrer">
          https://policies.google.com/privacy?hl=pt-BR&fg=1
        </a>
      </p>

      <h1>Instituições Financeiras</h1>

      <p>
        Os dados bancários dos Usuários, clientes, empregados e estagiários serão compartilhados com a instituição
        financeira prestadora de serviços de gateway de pagamento referente aos boletos de mensalidade, da remuneração
        de tais colaboradores, a partir da aplicação do banco.
      </p>

      <p>
        Os fornecedores de serviços terceirizados, tais como já citado gateways de pagamento e outros processadores de
        transação de pagamento, têm suas próprias políticas de privacidade com respeito à informação que somos obrigados
        a fornecer para eles de suas transações relacionadas com compras.
      </p>

      <h1>
        Tribunais, entes federativos, autarquias e autoridades públicas administrativas, como a Receita Federal do
        Brasil, Fazendas Estaduais, Cartórios, Juntas Comerciais, dentre outros
      </h1>

      <p>
        Os dados dos Usuários poderão ser compartilhados com o Poder Judiciário e o Poder Público de maneira geral,
        inclusive Juntas Comerciais, Receita Federal, Prefeituras Municipais, autoridades fazendárias diversas, dentre
        outros, bem como com empresas privadas, cartórios e pessoas físicas em geral, desde que necessário e adequado ao
        alcance da finalidade pretendida pelos clientes e à defesa dos seus direitos e interesses, quando da adesão ao
        “APP”.
      </p>

      <p>
        Tal compartilhamento ocorrerá, unicamente, com aqueles terceiros envolvidos na demanda e na medida em que for
        necessário para a resolução das questões jurídicas apresentadas ou se você violar nossos Termos de Serviço.
      </p>

      <h1>Alterações para política de privacidade e dados pessoais</h1>

      <p>
        Reservamos o direito de modificar este termo de uso e política de privacidade a qualquer momento, então por
        favor, revise-a com frequência.{' '}
      </p>

      <p>Alterações e esclarecimentos vão surtir efeito imediatamente após sua inserção no “APP”.</p>

      <p>
        Se fizermos alterações de materiais para essa política, iremos notificá-lo assim que eles foram atualizados,
        para que você tenha ciência sobre quais informações coletamos, como as usamos, e sob que circunstâncias, se
        alguma, usamos e/ou divulgamos elas.
      </p>

      <p>
        Se o “APP” for adquirida ou fundida com outra empresa, suas informações podem ser transferidas para os novos
        proprietários para que possamos continuar a exercer a finalidade do “APP”.
      </p>

      <p>Os Usuários que exercerem esses direitos não serão discriminados por isso.</p>

      <p>O “APP” não vende os dados pessoais dos Usuários.</p>

      <p>
        Além disso, ao tratar os seus dados, buscamos respeitar todos os princípios estabelecidos no artigo 6º da LGPD,
        especialmente o princípio da necessidade, que estabelece que o tratamento deve ser limitado ao mínimo necessário
        para a realização de suas ﬁnalidades, de forma proporcional e não excessiva.
      </p>

      <h1>Consentimento do usuário para o uso dos dados pessoais</h1>

      <p>O consentimento é concedido por meio da inserção dos dados pelo próprio Usuário durante o cadastro.</p>

      <p>
        Após a realização destas ações o Usuário assentiu com a coleta de dados para serem usados pelo “APP”. Caso sejam
        necessárias outras informações pessoais por uma razão secundária, como marketing, o Usuário será notificado para
        conceder diretamente a permissão, momento que também poderá negar o pedido.
      </p>

      <h1>COOKIES E TECNOLOGIAS DE TERCEIROS</h1>

      <p>
        O “APP” eventualmente poderá permitir que terceiros realizem medições de público online e serviços analíticos, a
        fim de oferecer anúncios sobre o “APP” na Internet e para acompanhar e gerar relatórios de desempenho desses
        anúncios.
      </p>

      <p>
        Esses terceiros podem usar cookies, web beacons, SDKs e outras tecnologias para identificar seu aparelho quando
        você acessar o “APP” ou site.
      </p>

      <h1>RETENÇÃO E EXCLUSÃO DOS DADOS DO USUÁRIO</h1>

      <p>
        O “APP” armazenará os seus Dados do Usuário desde a realização do cadastro e aceite das políticas, até a
        solicitação pelo Usuário de exclusão.
      </p>

      <p>O Usuário poderá solicitar a exclusão dos seus Dados do Usuário a qualquer momento.</p>

      <p>
        A menos que a lei exija de outra forma, ou caso o “APP” tenha um interesse legítimo que justifique a retenção
        continuada, excluiremos seus Dados do Usuário.
      </p>

      <p>
        Após o recebimento dessa solicitação, exceto pelo histórico de vagas para as quais você aceitou, candidatou-se
        ou foi considerado.
      </p>

      <h1>OUTRAS FINALIDADES DA COLETA DE DADOS</h1>

      <h3>Comunicação, Marketing e Publicação de Conteúdos</h3>
      <p>
        O “APP” poderá utilizar o e-mail de marketing e outras formas de contato aos Usuários, contendo conteúdos
        informativos, notícias e demais informações sobre suas áreas de atuação.
      </p>

      <p>
        Os destinatários dessas informações são os Usuários do “APP” que voluntariamente se cadastrem para tanto,
        fornecendo seus dados pessoais necessários a essa finalidade.
      </p>

      <p>
        Nestes e-mails o Usuário poderá receber notícias sobre nosso sistema de gestão, novos produtos e outras
        atualizações.
      </p>

      <h1>ALTERAÇÕES NA POLÍTICA DE PRIVACIDADE</h1>

      <p>O “APP” poderá alterar as Politicas de Privacidade ocasionalmente. </p>

      <p>Qualquer alteração feita entrará em vigor na data efetiva da disponibilização dos termos revisado.</p>

      <p>
        O “APP” tem o compromisso de salvaguardar a sua privacidade. Entre em contato conosco pelo
        e-mail (contato@cmssaude.com.br) caso tenha alguma dúvida ou problema com relação ao uso de seus Dados Pessoais
      </p>

      <h1>POLÍTICAS DE SEGURANÇA DO APP</h1>

      <p>
        Qualquer informação disponibilizada pelo Usuário será coletada e armazenada de acordo com os procedimentos de
        segurança considerados padrão pelo mercado, para tanto, o “APP” adota diversas precauções, em observância às
        diretrizes sobre padrões de segurança estabelecidas nas legislações e regulamentações aplicáveis, a seguir
        descritas
      </p>

      <h1>Objetivo da Política de Segurança</h1>

      <p>
        A Política de Segurança da Informação e Cibernética tem o objetivo de estabelecer diretrizes estratégicas,
        responsabilidades, competências, normas e procedimentos de uso do “APP”, visando assegurar a disponibilidade,
        integridade, confidencialidade e autenticidade dos dados e informações do “APP”, dos Usuários, parceiros,
        contratantes e do público em geral.
      </p>

      <h1>Abrangência da Política de Segurança</h1>

      <p>
        O acesso às informações ou dados do “APP” ou que estejam sob responsabilidade do “APP”, independente de seu
        vínculo com a empresa: dirigente, colaborador efetivo, estagiário, temporário ou terceiro, fornecedor e
        prestador de serviço.
      </p>

      <p>
        O acesso aos dados e a forma de tratamento é controlada por meio de permissões do usuário, determinadas em
        função da sua atividade e objetivo de tratamento do dado.
      </p>

      <h1>Segurança da Informação</h1>

      <p>
        O “APP” dispõe de medidas de segurança em âmbitos físico, eletrônicos e administrativos, que protegem os dados
        pessoais e suas informações.
      </p>

      <p>
        Essas medidas de proteção auxiliam na prevenção de fraudes e acessos não autorizados às informações, bem como na
        manutenção da integridade dos dados.
      </p>

      <h1>Política de Backups dos Dados</h1>

      <p>O “APP” possui 4 tipos de backups dos dados dos Usuários:</p>

      <ul>
        <li>Point In Time Recovery - Permite recuperar o dados em qualquer dia, hora e minuto;</li>
        <li>Backup full - Backup completo da base de dados feito automaticamente pelo banco de dados;</li>
        <li>Backup full - Backup completo da base de dados feito automaticamente pelo “APP”;</li>
        <li>Backup multicloud - Backup completo da base de dados feito para uma nuvem diferente da nuvem principal</li>
      </ul>

      <h1>Para prestar suporte técnico</h1>

      <p>
        A equipe técnica e de desenvolvimento do “APP” monitora todas as atividades do aplicativo, e sistema de gestão e
        os seus dados podem ser usados para ajudar em ações que visam prover suporte técnico e operacional, bem como
        garantir a segurança e a funcionalidade dos serviços; prevenir atividades ilegais, fraudulentas ou suspeitas que
        possam provocar danos ao “APP” ou à terceiros; e prevenir problemas técnicos ou de segurança.
      </p>

      <h1>LEGISLAÇÃO E FORO</h1>

      <p>
        Sem prejuízo de qualquer outra via de recurso administrativo. Este Termo será regido pela legislação brasileira.
        Qualquer reclamação ou controvérsia com base neste Termo será dirimida exclusivamente pela Comarca de São Paulo
        – Foro da Capital – Fórum João Mendes.
      </p>

      <h1>DISPOSIÇÕES GERAIS</h1>

      <p>
        O programa de segurança da informação e cibernética de uma empresa requer recursos tecnológicos e principalmente
        pessoas devidamente treinadas.
      </p>

      <p>A cultura de segurança da informação é um processo contínuo.</p>

      <p>
        O “APP” e os detentores de sua propriedade e direitos, entendem e prezam pelo compromisso com seus clientes,
        investidores, colaboradores e público em geral, buscando sempre atingir um nível de segurança aceitável, de modo
        a minimizar os impactos nos negócios e na prestação de serviços aos seus Usuários.
      </p>

      <p>
        O “APP” emprega as medidas apropriadas para proteger os dados pessoais contra perda, uso indevido, acesso não
        autorizado, divulgação, alteração e destruição, levando em consideração os riscos envolvidos no processamento e
        a natureza dos dados pessoais.
      </p>

      <p>
        São implementadas medidas técnicas e organizacionais apropriadas, projetadas para implementar princípios de
        proteção de dados, como minimização de dados, de maneira eficaz e integrar as salvaguardas necessárias ao
        processamento.
      </p>

      <p>
        Sempre que possível, os dados pessoais poderão ser criptografados com algoritmos de criptografia adequados e
        fortes.
      </p>

      <p>
        O Usuário se declara ciente que nenhum método de transmissão pela Internet ou método de armazenamento eletrônico
        é 100% seguro.
      </p>

      <p>
        O “APP” se compromete a realizar os melhores esforços para proteger os dados pessoais do Usuário; no entanto, o
        “APP” não pode garantir a segurança absoluta.
      </p>

      <p>
        Caso suas informações pessoais sejam comprometidas como uma violação da segurança, o “APP” notificará o Usuário
        e a ANPD em prazo razoável, conforme determina a LGPD.
      </p>

      <p>
        Se o usuário tiver alguma dúvida sobre a segurança de seus dados pessoais ou sobre a ferramenta, entre em
        contato por meio dos canais oficiais de suporte técnico, via (11) 98886-5694.
      </p>

      <h1>TERMOS DE USO DO APLICATIVO E POLÍTICA DE PRIVACIDADE</h1>

      <p>
        Este termo de serviço descreve as regras adotadas pelo “APP” com relação a coleta de dados, registro,
        armazenamento, tratamento, compartilhamento, eliminação de dados, bem como segurança e privacidade adotadas pelo
        sistema e seu modo de uso.
      </p>

      <p>
        Portanto, ficam incorporados a este instrumento a Política de Privacidade, Termo e Condições Gerais de Uso e
        Política de Segurança do Sistema, que integram a gestão, plano de governança e compliance do “APP”.
      </p>

      <p>Recomendamos a leitura atenta deste Instrumento.</p>

      <p>
        Ao acessar e utilizar as funcionalidades do “APP”, o usuário declara ter conhecimento do presente termo e estar
        ciente sobre as informações aqui presentes.
      </p>
    </StyledText>
  );
};

export { TermosDeUsoPrivacidade };
