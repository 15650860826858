import React from 'react';

import { Box } from '@mui/material';

import { useDevice } from '@components/utils';

const FullContainer = ({ sx, ...props }) => {
  const { isDesktop } = useDevice();
  const prefix = isDesktop() ? '' : 'd';
  return (
    <Box
      {...props}
      sx={{
        width: '100vw',
        height: `100${prefix}vh`,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        ...sx,
      }}
    />
  );
};

export { FullContainer };
