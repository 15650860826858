import React from 'react';

import { Card as MuiCard } from '@mui/material';

const Card = ({ sx, ...props }) => (
  <MuiCard
    {...props}
    sx={{
      width: { xs: 1 },
      mb: '1rem',
      '&:first-of-type': {
        mt: '1rem',
      },
      ...sx,
    }}
  />
);

export { Card };
