import React, { useEffect, useRef, useState } from 'react';

import { TextField } from '@mui/material';

let InputMask;

if (typeof window !== `undefined`) {
  InputMask = require('inputmask').default;
}

function MaskedInput({ onChange, mask, maskOptions = {}, ...props }) {
  const elRef = useRef(null);

  const [masked, setMasked] = useState(null);

  useEffect(() => {
    if (mask && !masked) {
      const el = elRef.current;

      const m = new InputMask({ mask, ...maskOptions }).mask(el);

      setMasked(m);
    }
  }, [mask, maskOptions, masked]);

  const changeHandler = (e) => {
    if (onChange)
      onChange({
        target: { name: e.target.name, value: masked.unmaskedvalue(), isComplete: e.target.inputmask.isComplete() },
      });
  };

  return <TextField inputRef={elRef} {...props} onChange={changeHandler} />;
}

export { MaskedInput };
