import React from 'react';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ptBR } from 'date-fns/locale';
import { ptBR as xptBR } from '@mui/x-date-pickers/locales';
import { setDefaultOptions } from 'date-fns';

import {
  AuthProvider,
  TermsProvider,
  NotificationsProvider,
  UserProvider,
  VagasProvider,
  CompanyProvider,
  SnackbarProvider,
  EscalaApiProvider,
  VBEProvider,
} from './src/context';

setDefaultOptions({ locale: ptBR });

if (typeof navigator.serviceWorker !== 'undefined') {
  navigator.serviceWorker.register('sw.js');
}

export const wrapRootElement = ({ element }) => (
  <LocalizationProvider
    dateAdapter={AdapterDateFns}
    localeText={xptBR.components.MuiLocalizationProvider.defaultProps.localeText}
    adapterLocale={ptBR}
  >
    <AuthProvider>
      <TermsProvider>
        <NotificationsProvider>
          <UserProvider>
            <VagasProvider>
              <CompanyProvider>
                <EscalaApiProvider>
                  <VBEProvider>
                    <SnackbarProvider>{element}</SnackbarProvider>
                  </VBEProvider>
                </EscalaApiProvider>
              </CompanyProvider>
            </VagasProvider>
          </UserProvider>
        </NotificationsProvider>
      </TermsProvider>
    </AuthProvider>
  </LocalizationProvider>
);
