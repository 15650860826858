import React from 'react';
import { navigate } from 'gatsby';

import { Autocomplete, Button, Box, TextField } from '@mui/material';

const CompanyAutoComplete = ({ companys, values, errors, changeAutocompleteHandler }) => (
  <Autocomplete
    options={[...companys, { name: '', type: 'button' }]}
    getOptionLabel={(option) => option.name}
    renderOption={(props, option) => {
      if (option.type === 'button')
        return (
          <Box component="li" {...props} sx={{ display: 'flex', justifyContent: 'center', mt: '1rem' }}>
            <Button
              variant="contained"
              color="primary"
              sx={{ mx: 'auto' }}
              onClick={() => navigate('/instituicoes/cadastrar')}
            >
              Nova instituição +
            </Button>
          </Box>
        );

      return (
        <Box component="li" {...props}>
          {option.name}
        </Box>
      );
    }}
    renderInput={(params) => (
      <TextField
        {...params}
        name="company"
        label="Instituição"
        error={Boolean(errors.company)}
        helperText={errors.company ?? ' '}
      />
    )}
    value={values.company}
    onChange={changeAutocompleteHandler('company')}
  />
);

export { CompanyAutoComplete };
