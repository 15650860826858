import React from 'react';

import { StyledText } from './StyledText';

const TermosDeUso = () => {
  return (
    <StyledText>
      <h1>TERMOS DE USO DO “APP”</h1>

      <p>Ao usar o “APP”, você conﬁrma que leu, compreendeu e aceita os termos de uso e ﬁca a eles vinculado.</p>

      <h1>Informações sobre como usar o “APP”</h1>

      <p>
        O “APP” é uma plataforma de gestão online vagas disponíveis na área da saúde, por meio da qual são
        disponibilizas vagas ou postos de trabalho fixos ou temporários previamente cadastradas por entidades de saúde,
        como hospitais, clínicas, postos de saúde e demais entidades ligadas à saúde.
      </p>

      <p>
        O uso do “APP” não confere ao Usuário a propriedade sobre direitos de propriedade intelectual sobre os serviços
        ou sobre o conteúdo acessado.
      </p>

      <p>
        Estes termos não conferem ao Usuário o direito de usar quaisquer marcas, conteúdos ou logotipos usados no “APP”.
      </p>

      <p>Não remova, oculte ou altere quaisquer avisos legais exibidos pelo “APP”.</p>

      <p>
        O “APP” poderá enviar aos Usuários anúncios de serviços, mensagens administrativas e outras informações. Mas o
        Usuário pode desativar essas comunicações a qualquer momento.
      </p>

      <h1>Instruções ao usuário, quanto a sua conta</h1>

      <p>
        Para fazer uso do “APP” é necessário que o Usuário crie uma conta através de cadastro de usuário na plataforma,
        sendo necessário informar, entre outros dados, endereço de e-mail e senha pessoal que será intransferível, o
        cadastro autoriza o envio de atualizações da plataforma.
      </p>

      <p>O Usuário é o único responsável, para todos os fins, pelas operações efetuadas em sua conta.</p>

      <p>Para garantir a proteção da sua conta, o usuário deve manter a senha em sigilo ou logout após cada sessão.</p>

      <p>Toda atividade realizada na conta ou por seu intermédio é de responsabilidade do Usuário.</p>

      <p>
        Não recomendamos que a senha da conta seja empregada em aplicativos de terceiros ou computadores públicos, caso
        tome conhecimento de uso não autorizado da sua senha ou conta, providencie a alteração da senha e notifique o
        setor de segurança da informação, por meio do suporte online, para as providências necessárias.
      </p>

      <h1>Responsabilidades do Usuário</h1>

      <p>
        a) Inserir apenas informações verdadeiras no sistema e se responsabilizar por suas declarações e autorizações.
      </p>

      <p>
        b) Zelar pelos dados de sua identificação digital individual sempre que acessar a Internet, informando-os apenas
        em operações em que exista a proteção de dados.
      </p>

      <p>
        c) Usar as tecnologias fornecidas pelo smartphone ou tablet para proteger a sua privacidade perante terceiros;
      </p>

      <p>
        d) Manter em sigilo as informações e mensagens recebidas em consonância com os termos de privacidade do “APP”;
      </p>

      <p>
        e) Não permitir que terceiros utilizem o “APP” no seu celular ou tablet; e cumprir rigorosamente todas as
        determinações e procedimentos previstos neste termo;
      </p>

      <p>
        f) Não enviar mensagens e conteúdo que possam ser categorizados como ilegais, imorais, degradantes,
        preconceituosos.
      </p>

      <h1>Das violações aos termos de uso</h1>

      <p>
        O Usuário pode acessar os produtos e serviços para fins lícitos, sendo este completamente responsável pelo
        conhecimento e pela adesão a qualquer e toda lei, regulamento e regras pertinentes ao uso dos produtos e
        serviços sobre os quais tratam o presente termo.
      </p>

      <p>
        Não é permitido reproduzir, distribuir, transmitir, exibir, vender, licenciar, alienar de qualquer forma ou
        explorar qualquer conteúdo acessado no “APP” para quaisquer fins, onerosos ou não.
      </p>

      <p>
        Na hipótese de qualquer medida, seja ela judicial ou não, tomada por terceiros contra o “APP”, em razão de
        qualquer conteúdo postado, publicizado ou de qualquer outra forma disponibilizado pelo Usuário do “APP”, o Grupo
        de empresas detentores dos direitos do “APP” poderá voltar tal medida contra o responsável.
      </p>
    </StyledText>
  );
};

export { TermosDeUso };
