import React from 'react';
import { StyledText } from './StyledText';

const Encerramento = () => {
  return (
    <StyledText>
      <h1>Para prestar suporte técnico</h1>

      <p>
        A equipe técnica e de desenvolvimento do “APP” monitora todas as atividades do aplicativo, e sistema de gestão e
        os seus dados podem ser usados para ajudar em ações que visam prover suporte técnico e operacional, bem como
        garantir a segurança e a funcionalidade dos serviços; prevenir atividades ilegais, fraudulentas ou suspeitas que
        possam provocar danos ao “APP” ou à terceiros; e prevenir problemas técnicos ou de segurança.
      </p>

      <h1>LEGISLAÇÃO E FORO</h1>

      <p>
        Sem prejuízo de qualquer outra via de recurso administrativo. Este Termo será regido pela legislação brasileira.
        Qualquer reclamação ou controvérsia com base neste Termo será dirimida exclusivamente pela Comarca de São Paulo
        – Foro da Capital – Fórum João Mendes.
      </p>

      <h1>DISPOSIÇÕES GERAIS</h1>

      <p>
        O programa de segurança da informação e cibernética de uma empresa requer recursos tecnológicos e principalmente
        pessoas devidamente treinadas.{' '}
      </p>

      <p>A cultura de segurança da informação é um processo contínuo.</p>

      <p>
        O “APP” e os detentores de sua propriedade e direitos, entendem e prezam pelo compromisso com seus clientes,
        investidores, colaboradores e público em geral, buscando sempre atingir um nível de segurança aceitável, de modo
        a minimizar os impactos nos negócios e na prestação de serviços aos seus Usuários.
      </p>

      <p>
        O “APP” emprega as medidas apropriadas para proteger os dados pessoais contra perda, uso indevido, acesso não
        autorizado, divulgação, alteração e destruição, levando em consideração os riscos envolvidos no processamento e
        a natureza dos dados pessoais.
      </p>

      <p>
        São implementadas medidas técnicas e organizacionais apropriadas, projetadas para implementar princípios de
        proteção de dados, como minimização de dados, de maneira eficaz e integrar as salvaguardas necessárias ao
        processamento.
      </p>

      <p>
        Sempre que possível, os dados pessoais poderão ser criptografados com algoritmos de criptografia adequados e
        fortes.
      </p>

      <p>
        O Usuário se declara ciente que nenhum método de transmissão pela Internet ou método de armazenamento eletrônico
        é 100% seguro.
      </p>

      <p>
        O “APP” se compromete a realizar os melhores esforços para proteger os dados pessoais do Usuário; no entanto, o
        “APP” não pode garantir a segurança absoluta.
      </p>

      <p>
        Caso suas informações pessoais sejam comprometidas como uma violação da segurança, o “APP” notificará o Usuário
        e a ANPD em prazo razoável, conforme determina a LGPD.
      </p>

      <p>
        Se o usuário tiver alguma dúvida sobre a segurança de seus dados pessoais ou sobre a ferramenta, entre em
        contato por meio dos canais oficiais de suporte técnico, via telefone no (11) 98886-5694.
      </p>
    </StyledText>
  );
};

export { Encerramento };
