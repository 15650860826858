import React from 'react';

import { Box, FormControlLabel, Switch } from '@mui/material';

const SwitchInput = ({ formControlProps, switchProps }) => (
  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <FormControlLabel
      {...formControlProps}
      labelPlacement="top"
      control={<Switch {...switchProps} />}
      sx={{
        '& .MuiFormControlLabel-label': {
          whiteSpace: 'nowrap',
        },
      }}
    />
  </Box>
);

export { SwitchInput };
