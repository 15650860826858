import React from 'react';

import { StyledText } from './StyledText';

const PoliticaDePrivacidade = () => {
  return (
    <StyledText>
      <h1>POLÍTICA DE PRIVACIDADE</h1>

      <h3>Coleta de dados</h3>

      <p>Os dados pessoais tratados pelo “APP” são informados diretamente pelo Usuário, por meio do seu cadastro.</p>

      <p>O “APP” poderá registrar todas as atividades efetuadas pelo usuário por meio de logs.</p>

      <p>
        O “APP” coleta dados que consideramos indispensáveis para o funcionamento de nossos diversos serviços, tais
        como:
      </p>

      <ul>
        <li>Nome completo;</li>
        <li>Numero do CRM</li>
        <li>Endereço;</li>
        <li>Endereço de e-mail;</li>
        <li>Telefones;</li>
        <li>Currículos;</li>
        <li>Formação profissional;</li>
        <li>Carta de apresentação;</li>
        <li>Especialidades que atua;</li>
        <li>Especialidades pretendidas;</li>
      </ul>

      <p>Ao cadastrar-se no “APP” autoriza-nos a receber as seguintes categorias de informação de outras fontes:</p>

      <ul>
        <li>
          Onde permitido por lei, dados de checagem de antecedentes, possivelmente incluindo seus registros de
          antecedentes criminais;
        </li>
        <li>Informações sobre atuação profissional anterior.</li>
      </ul>

      <h1>USO DOS DADOS DO USUÁRIO</h1>

      <p>
        O “APP” armazenará e utilizará os seus Dados do Usuário no processo de recrutamento do Usuário para vagas
        profissionais.
      </p>

      <p>
        Todo Usuário cadastrado autoriza o uso de seus Dados do Usuário para identificarmos e entrarmos em contato a
        respeito de vagas que possam ser do seu interesse. Se não quiser que o “APP” use seus dados para esse fim, você
        poderá solicitar a exclusão dos seus Dados do Usuário.
      </p>

      <h1>COMPARTILHAMENTO DOS DADOS DE CANDIDATO</h1>

      <p>O “APP” pode compartilhar seus Dados do Usuário:</p>

      <ul>
        <li>
          com as empresas parceiras e/ou contratantes do “APP” que buscam profissionais através da disponibilização de
          vagas;
        </li>
        <li>
          com fornecedores, consultores e outros prestadores de serviços que precisem acessar essas informações para
          executar tarefas relacionadas ao processo de recrutamento em nome do Usuário;
        </li>
        <li>
          quando relacionados a, ou durante as negociações de: fusões, venda do “APP”, consolidações/reestruturações,
          financiamentos ou aquisições (integrais ou parciais) do Grupo de empresas detentoras dos direitos do “APP”;
        </li>
        <li>
          com autoridades policiais/judiciais, autoridades governamentais ou outros terceiros; se acreditarmos ser
          necessário em cumprimento com leis e regulamentos vigentes, acordos de operação, processos judiciais (como
          intimações ou mandados) ou exigências governamentais; se acreditarmos que suas ações são incoerentes com as
          nossas políticas; ou se acreditarmos que esse compartilhamento é necessário para proteger os direitos, a
          propriedade ou a segurança do “APP” ou outras partes; e
        </li>
        <li>de forma agregada e/ou anonimizada que não possa ser adequadamente utilizada para identificar você.</li>
      </ul>

      <h1>SEUS DIREITOS</h1>

      <p>Em relação ao tratamento dos seus Dados do Usuário por parte da “APP”, você tem o direito de:</p>

      <ul>
        <li>Acessar seus dados pessoais;</li>
        <li>Corrigir eventuais erros relacionados aos seus dados pessoais;</li>
        <li>Apagar ou excluir seus Dados do Usuário conforme os termos acima, e;</li>
        <li>Revogar seu consentimento ao processamento dos seus Dados do Usuário.</li>
      </ul>

      <h1>Informação sobre o compartilhamento</h1>

      <p>
        Você tem o direito de saber as entidades públicas e privadas com as quais a “APP” realiza uso compartilhado de
        dados.{' '}
      </p>
      <p>
        Manteremos, no item "Compartilhamento de dados com terceiros” desta Política, a indicação das modalidades e
        atividades que podem envolver compartilhamento de Dados com terceiros.{' '}
      </p>
      <p>
        Em todo caso, O Usuário tenha dúvidas ou quiser maiores detalhes, tem o direito de nos solicitar essas
        informações.
      </p>
      <p>
        Em alguns casos, o exercício dos seus direitos de contestar ou restringir o processamento dos seus Dados do
        Usuário, bem como de revogar o consentimento para o processamento desses dados, pode afetar a capacidade que
        temos de avaliar vagas disponíveis compatíveis com o perfil profissional do Usuário.{' '}
      </p>

      <h1>Compartilhamento de dados com terceiros</h1>

      <p>
        O “APP” poderá compartilhar dados com algumas instituições parceiras, com o propósito de viabilizar sua
        atividade comercial, otimizá-la e cumprir determinações legais, proporcionando melhores resultados aos seus
        Usuários, clientes, colaboradores e visitantes, baseado, sempre, nos princípios da necessidade, da finalidade e
        da adequação, para a realização de tais compartilhamentos.
      </p>

      <p>
        No geral, os fornecedores terceirizados usados por nós irão apenas coletar, usar e divulgar suas informações na
        medida do necessário para permitir que eles realizem os serviços que eles nos fornecem.{' '}
      </p>

      <p>
        A seguir as empresas parceiras:
        <br />
        - Grupo CMSS
        <br />
        - Executivos da Saúde
        <br />- FCM – Formação de Coordenadores Médicos
      </p>

      <h1>Banco de dados</h1>

      <p>
        Usamos como banco de dados para armazenamento o Firebase, da Google. Em sua política de privacidade igualmente,
        declara prestar suas atividades de forma adequada à legislação vigente em privacidade e proteção de dados. Os
        termos da sua política de privacidade e proteção de dados pessoais disponível em{' '}
        <a href="https://firebase.google.com/support/privacy?hl=pt-br" target="_blank" rel="noopener noreferrer">
          https://firebase.google.com/support/privacy?hl=pt-br
        </a>
        .
      </p>

      <h1>Servidores de e-mail</h1>

      <p>
        Os e-mails institucionais de empregados, Usuários e clientes bem como eventuais informações pessoais constantes
        do banco de dados correspondente ao histórico desses e-mails serão, automaticamente, compartilhados com as
        empresas proprietárias dos servidores de e-mails usados pela equipe do “APP”.
      </p>

      <p>
        Atualmente, tais fornecedoras são GOOGLE detentora do software GMAIL, que tem o acesso intermediado pela
        plataforma de integração Interceleri.{' '}
      </p>

      <p>
        Todas as empresas declaram estar com suas atividades e o tratamento de dados pessoais adequados às normas da
        LGPD, conforme as respectivas políticas, acessíveis em:
        <br /> links:
        <br />
        <a href="https://www.inteceleri.com.br/" target="_blank" rel="noopener noreferrer">
          https://www.inteceleri.com.br/
        </a>
        <br />
        <a href="https://policies.google.com/privacy?hl=pt-BR&fg=1" target="_blank" rel="noopener noreferrer">
          https://policies.google.com/privacy?hl=pt-BR&fg=1
        </a>
      </p>

      <h1>Instituições Financeiras</h1>

      <p>
        Os dados bancários dos Usuários, clientes, empregados e estagiários serão compartilhados com a instituição
        financeira prestadora de serviços de gateway de pagamento referente aos boletos de mensalidade, da remuneração
        de tais colaboradores, a partir da aplicação do banco.
      </p>

      <p>
        Os fornecedores de serviços terceirizados, tais como já citado gateways de pagamento e outros processadores de
        transação de pagamento, têm suas próprias políticas de privacidade com respeito à informação que somos obrigados
        a fornecer para eles de suas transações relacionadas com compras.
      </p>

      <h1>
        Tribunais, entes federativos, autarquias e autoridades públicas administrativas, como a Receita Federal do
        Brasil, Fazendas Estaduais, Cartórios, Juntas Comerciais, dentre outros
      </h1>

      <p>
        Os dados dos Usuários poderão ser compartilhados com o Poder Judiciário e o Poder Público de maneira geral,
        inclusive Juntas Comerciais, Receita Federal, Prefeituras Municipais, autoridades fazendárias diversas, dentre
        outros, bem como com empresas privadas, cartórios e pessoas físicas em geral, desde que necessário e adequado ao
        alcance da finalidade pretendida pelos clientes e à defesa dos seus direitos e interesses, quando da adesão ao
        “APP”.
      </p>

      <p>
        Tal compartilhamento ocorrerá, unicamente, com aqueles terceiros envolvidos na demanda e na medida em que for
        necessário para a resolução das questões jurídicas apresentadas ou se você violar nossos Termos de Serviço.
      </p>

      <h1>Alterações para política de privacidade e dados pessoais</h1>

      <p>
        Reservamos o direito de modificar este termo de uso e política de privacidade a qualquer momento, então por
        favor, revise-a com frequência.{' '}
      </p>

      <p>Alterações e esclarecimentos vão surtir efeito imediatamente após sua inserção no “APP”.</p>

      <p>
        Se fizermos alterações de materiais para essa política, iremos notificá-lo assim que eles foram atualizados,
        para que você tenha ciência sobre quais informações coletamos, como as usamos, e sob que circunstâncias, se
        alguma, usamos e/ou divulgamos elas.
      </p>

      <p>
        Se o “APP” for adquirida ou fundida com outra empresa, suas informações podem ser transferidas para os novos
        proprietários para que possamos continuar a exercer a finalidade do “APP”.
      </p>

      <p>Os Usuários que exercerem esses direitos não serão discriminados por isso.</p>

      <p>O “APP” não vende os dados pessoais dos Usuários.</p>

      <p>
        Além disso, ao tratar os seus dados, buscamos respeitar todos os princípios estabelecidos no artigo 6º da LGPD,
        especialmente o princípio da necessidade, que estabelece que o tratamento deve ser limitado ao mínimo necessário
        para a realização de suas ﬁnalidades, de forma proporcional e não excessiva.
      </p>

      <h1>Consentimento do usuário para o uso dos dados pessoais</h1>

      <p>O consentimento é concedido por meio da inserção dos dados pelo próprio Usuário durante o cadastro.</p>

      <p>
        Após a realização destas ações o Usuário assentiu com a coleta de dados para serem usados pelo “APP”. Caso sejam
        necessárias outras informações pessoais por uma razão secundária, como marketing, o Usuário será notificado para
        conceder diretamente a permissão, momento que também poderá negar o pedido.
      </p>

      <h1>COOKIES E TECNOLOGIAS DE TERCEIROS</h1>

      <p>
        O “APP” eventualmente poderá permitir que terceiros realizem medições de público online e serviços analíticos, a
        fim de oferecer anúncios sobre o “APP” na Internet e para acompanhar e gerar relatórios de desempenho desses
        anúncios.
      </p>

      <p>
        Esses terceiros podem usar cookies, web beacons, SDKs e outras tecnologias para identificar seu aparelho quando
        você acessar o “APP” ou site.
      </p>

      <h1>RETENÇÃO E EXCLUSÃO DOS DADOS DO USUÁRIO</h1>

      <p>
        O “APP” armazenará os seus Dados do Usuário desde a realização do cadastro e aceite das políticas, até a
        solicitação pelo Usuário de exclusão.
      </p>

      <p>O Usuário poderá solicitar a exclusão dos seus Dados do Usuário a qualquer momento.</p>

      <p>
        A menos que a lei exija de outra forma, ou caso o “APP” tenha um interesse legítimo que justifique a retenção
        continuada, excluiremos seus Dados do Usuário.
      </p>

      <p>
        Após o recebimento dessa solicitação, exceto pelo histórico de vagas para as quais você aceitou, candidatou-se
        ou foi considerado.
      </p>

      <h1>OUTRAS FINALIDADES DA COLETA DE DADOS</h1>

      <h3>Comunicação, Marketing e Publicação de Conteúdos</h3>
      <p>
        O “APP” poderá utilizar o e-mail de marketing e outras formas de contato aos Usuários, contendo conteúdos
        informativos, notícias e demais informações sobre suas áreas de atuação.
      </p>

      <p>
        Os destinatários dessas informações são os Usuários do “APP” que voluntariamente se cadastrem para tanto,
        fornecendo seus dados pessoais necessários a essa finalidade.
      </p>

      <p>
        Nestes e-mails o Usuário poderá receber notícias sobre nosso sistema de gestão, novos produtos e outras
        atualizações.
      </p>

      <h1>ALTERAÇÕES NA POLÍTICA DE PRIVACIDADE</h1>

      <p>O “APP” poderá alterar as Politicas de Privacidade ocasionalmente. </p>

      <p>Qualquer alteração feita entrará em vigor na data efetiva da disponibilização dos termos revisado.</p>

      <p>
        O “APP” tem o compromisso de salvaguardar a sua privacidade. Entre em contato conosco pelo
        e-mail (contato@cmssaude.com.br) caso tenha alguma dúvida ou problema com relação ao uso de seus Dados Pessoais
      </p>
    </StyledText>
  );
};

export { PoliticaDePrivacidade };
