import React from 'react';

import { Button } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import { useDevice } from '@components/utils';

function SuporteButton() {
  const { isDesktop } = useDevice();

  return (
    <Button
      variant="contained"
      sx={{ gap: '1rem' }}
      component="a"
      href={`https://${isDesktop() ? 'web' : 'api'}.whatsapp.com/send?phone=5511988433679`}
      target="_blank"
      rel="noopener noreferer"
    >
      suporte <WhatsAppIcon />
    </Button>
  );
}

export { SuporteButton };
