import React from 'react';

import { Button } from '@mui/material';

const SimpleButton = ({ sx, ...props }) => (
  <Button
    variant="contained"
    color="primary"
    sx={{
      width: 1,
      p: '0.8rem',
      backgroundColor: 'var(--blue-1)',
      textTransform: 'none',
      '&:hover': { backgroundColor: 'var(--blue-2)' },
      ...sx,
    }}
    {...props}
  />
);

export { SimpleButton };
