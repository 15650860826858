import React from 'react';
import { navigate } from 'gatsby';

import { Box, Button, Container } from '@mui/material';

import { containerMaxWidth } from '@components/utils';
import { FooterContainer } from './FooterContainer';

const Footer = ({ children, showBack, backURL }) => {
  return (
    <FooterContainer sx={{ position: 'fixed', bottom: 0, left: 0, px: '1.5rem' }}>
      <Container
        maxWidth={containerMaxWidth}
        sx={{
          borderTop: '1px solid var(--gray-3)',
          display: 'flex',
          alignItems: 'center',
          height: 1,
          px: 0,
        }}
      >
        {showBack && (
          <Button
            sx={{ textTransform: 'none' }}
            onClick={() => {
              navigate(backURL);
            }}
          >
            voltar
          </Button>
        )}
        {children}
      </Container>
    </FooterContainer>
  );
};

export { Footer };
