import React from 'react';

import { Box } from '@mui/material';

import { useDevice } from '@components/utils';

const FooterContainer = ({ children, sx = {} }) => {
  const { isIphone } = useDevice();

  return (
    <Box
      sx={{
        width: 1,
        height: '68px',
        minHeight: '68px',
        ...(isIphone() ? { pb: '1.5rem' } : {}),
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export { FooterContainer };
