module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"CEMED","short_name":"CEMED","start_url":"/","theme_color":"#3D4458","display":"fullscreen","background_color":"#3d4458","description":"O CEMED centraliza em uma só plataforma dados de quem quer contratar e de quem quer ser contratado, proporcionando maior facilidade na hora de analisar perfis e encontrar a vaga perfeita.","id":"com.cmsss.app","dir":"ltr","orientation":"portrait","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6af25e1fb086caa55b962b465d6242ba"},
    },{
      plugin: require('../plugins/gatsby-plugin-mui-emotion/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/gatsby-plugin-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
