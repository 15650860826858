let deviceDetect;

if (typeof window !== 'undefined') {
  const DD = require('device-detect');

  deviceDetect = DD();
}

const desktops = ['windows', 'macintosh', 'linux'];

export function useDevice() {
  const isIphone = () => deviceDetect && deviceDetect.device.toLowerCase() === 'iphone';

  const isDesktop = () => desktops.includes(deviceDetect?.device?.toLowerCase());

  return {
    isIphone,
    isDesktop,
  };
}
