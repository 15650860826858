import React from 'react';
import { nanoid } from 'nanoid';

import { Stepper, Step, StepLabel, Fab, Box, lighten } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import PersonIcon from '@mui/icons-material/Person';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import PlaceIcon from '@mui/icons-material/Place';
import CheckIcon from '@mui/icons-material/Check';

const steps = [
  { id: nanoid(), name: 'login', Icon: LockIcon },
  { id: nanoid(), name: 'profile', Icon: PersonIcon },
  { id: nanoid(), name: 'type', Icon: SwitchAccountIcon },
  { id: nanoid(), name: 'doctor', Icon: MedicalServicesIcon },
  { id: nanoid(), name: 'address', Icon: PlaceIcon },
  { id: nanoid(), name: 'confirm', Icon: CheckIcon },
];

const RegisterMenu = () => {
  return (
    <Stepper
      alternativeLabel
      activeStep={1}
      sx={{ width: 1 }}
      connector={
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '-50%',
            right: '50%',
            height: '5px',
            transform: 'translateY(-50%)',
            backgroundColor: 'var(--blue-1)',
            zIndex: 1,
          }}
        />
      }
    >
      {steps.map((item) => (
        <Step key={item.id}>
          <StepLabel StepIconComponent={(props) => <StepIcon {...props} Icon={item.Icon} />}></StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

const StepIcon = ({ active, completed, error, Icon }) => {
  return (
    <Fab
      color={completed ? 'primary' : 'default'}
      disabled={!active && !completed}
      sx={{ '&.Mui-disabled': { backgroundColor: lighten('#9BA4B2', 0.5) } }}
    >
      <Icon />
    </Fab>
  );
};

export { RegisterMenu };
