import React from 'react';

import { Container, Box } from '@mui/material';

import { containerMaxWidth, useDevice } from '@components/utils';

export function Content({ children, noMenu, maxWidth = containerMaxWidth, sx = {}, FullContent }) {
  const { isDesktop, isIphone } = useDevice();
  const prefix = isDesktop() ? '' : 'd';
  const hfactor = isIphone() ? '150' : '120';

  return (
    <Box
      sx={{
        width: 1,
        overflowY: 'auto',
        // height: noMenu ? `calc(100${prefix}vh - 120px)` : `calc(100${prefix}vh - 184px)`,
        height: `calc(100${prefix}vh - ${hfactor}px)`,
        ...sx,
      }}
    >
      {!FullContent ? (
        <Container maxWidth={maxWidth} sx={{ height: 1 }}>
          {children}
        </Container>
      ) : (
        <FullContent />
      )}
    </Box>
  );
}
