import React from 'react';

import { FullContainer } from './FullContainer';
import { Content } from './Content';
import { TopBar } from './Topbar';

function LoginLayout({ children, contentProps }) {
  return (
    <>
      <FullContainer
        sx={{ background: 'linear-gradient(197.56deg, #D9E1F2 0%, #FFFFFF 100%)', flexDirection: 'column' }}
      >
        <TopBar />
        <Content noMenu {...contentProps}>
          {children}
        </Content>
      </FullContainer>
    </>
  );
}

export { LoginLayout };
