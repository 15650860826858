import React from 'react';

import { Box } from '@mui/material';

const StyledText = ({ sx, ...props }) => (
  <Box
    {...props}
    sx={{
      '& h1': {
        fontSize: '1.5rem',
        textDecoration: 'underline',
        textAlign: 'center',
      },

      '& h3': {
        textDecoration: 'underline',
        textAlign: 'center',
      },
    }}
  />
);

export { StyledText };
