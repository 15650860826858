import React from 'react';

import { StyledText } from './StyledText';

const TermosDefinicoes = () => {
  return (
    <StyledText>
      <h1>TERMOS DE SERVIÇOS DO APLICATIVO E POLÍTICA DE PRIVACIDADE</h1>

      <p>
        Este termo de serviço descreve as regras adotadas pelo “APP” com relação a coleta de dados, registro,
        armazenamento, tratamento, compartilhamento, eliminação de dados, bem como segurança e privacidade adotadas pelo
        sistema e seu modo de uso.
      </p>

      <p>
        Portanto, ficam incorporados a este instrumento a Política de Privacidade, Termo e Condições Gerais de Uso e
        Política de Segurança do Sistema, que integram a gestão, plano de governança e compliance do “APP”.
      </p>

      <p>Recomendamos a leitura atenta deste Instrumento.</p>

      <p>
        Ao acessar e utilizar as funcionalidades do “APP”, o usuário declara ter conhecimento do presente termo e estar
        ciente sobre as informações aqui presentes.
      </p>

      <h1>DEFINIÇÕES E SIGLAS</h1>

      <p>Para os fins deste Termo de serviço, devem se considerar as seguintes definições e descrições:</p>

      <p>
        Dados de Uso: são dados coletados automaticamente, gerados pelo uso do “APP” ou pela própria infraestrutura do
        serviço do “APP”;
      </p>

      <p>
        <b>LGPD:</b> Lei Geral de Proteção de Dados Pessoais (LGPD), Lei nº 13.709, de 14 de agosto de 2018.
      </p>

      <p>
        <b>Titular dos Dados:</b> Pessoa natural a quem se referem os dados pessoais que são objeto de Tratamento.
      </p>

      <p>
        <b>Dado Pessoal:</b> informação relacionada a pessoa natural, direta ou indiretamente, identificada ou
        identificável
      </p>

      <p>
        <b>Dado Pessoal Sensível:</b> dado pessoal sobre origem racial ou étnica, convicção religiosa, opinião política,
        filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à
        vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa natural.
      </p>

      <p>
        <b>Tratamento:</b> Toda operação realizada com Dados Pessoais, como coleta, registro, armazenamento, uso,
        compartilhamento, enriquecimento e eliminação.
      </p>

      <p>
        <b>Usuários:</b> Pessoas ou indivíduos que acessam ou interagem com as atividades oferecidas pelo serviço pelo
        “APP”, que são também Titulares de Dados Pessoais passíveis de Tratamento.
      </p>

      <p>
        <b>Conta de Acesso:</b> Credencial de um usuário necessária para utilizar ou acessar áreas restritas ou produtos
        e serviços do “APP”.
      </p>

      <p>
        <b>Encarregado/DPO (Data Protection Officer):</b> O responsável pelos Dados Pessoais dos Usuários na plataforma
        do “APP”, no que diz respeito à proteção da privacidade dos Usuários.
      </p>

      <p>
        <b>Controlador:</b> pessoa natural ou jurídica, de direito público ou privado, a quem competem as decisões
        referentes ao tratamento de Dados Pessoais, como: quais Dados Pessoais coletar, de quem coletar, qual é a
        finalidade do Tratamento e como é feito o tratamento.
      </p>

      <p>
        <b>Operador:</b> é o agente responsável por realizar o Tratamento de dados em nome do Controlador e conforme a
        finalidade por este delimitada.
      </p>

      <p>
        <b>Cookies:</b> Os cookies são pequenos arquivos eletrônicos armazenados no dispositivo do usuário, que permitem
        à plataforma distinguir o visitante em um novo acesso e manter as suas preferências ao longo da respectiva
        sessão.
      </p>

      <p>
        <b>Logs:</b> Registros de atividades dos usuários efetuadas no “APP”.
      </p>

      <h1>INFORMAÇÃO DO USUÁRIO</h1>

      <p>
        O “APP” coleta, usa, divulga e processa os dados pessoais (“Dados de Usuário”) que se cadastra no
        “APP” (“Usuário”).
      </p>

      <h1>ESCOPO E APLICAÇÃO</h1>

      <p>
        Esta Política de Privacidade se aplica ao processamento de Dados do Usuário feito pelo “APP”, em todo o
        território nacional.
      </p>
    </StyledText>
  );
};

export { TermosDefinicoes };
