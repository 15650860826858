import React from 'react';
import { useMediaQuery } from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';

function DatePicker({ isTime, ...props }) {
  const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  if (isTime) {
    return <DesktopTimePicker {...props} />;
  }

  return downSm ? <MobileDatePicker {...props} /> : <MuiDatePicker {...props} />;
}

export { DatePicker };
