import React from 'react';

import { Snackbar, Alert, Slide } from '@mui/material';

export function SnackMesage({ openMsg, closeHandler, message, severity }) {
  return (
    <Snackbar
      open={openMsg}
      onClose={closeHandler}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      autoHideDuration={6000}
      TransitionComponent={Slide}
    >
      <Alert severity={severity ?? 'error'} variant="filled" onClose={closeHandler}>
        {message ?? ''}
      </Alert>
    </Snackbar>
  );
}
