import React from 'react';

import { Box } from '@mui/material';

import { menuH, footerH } from '@components/utils';

const Flex = ({ sx, nomenu = false, hasFooter = false, ...props }) => {
  const getHeight = () => {
    if (!nomenu) return `calc(100% - ${menuH}px)`;

    if (hasFooter) return `calc(100% - ${footerH}px)`;

    return 1;
  };
  return (
    <Box
      {...props}
      sx={{
        width: 1,
        height: getHeight(),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...sx,
      }}
    />
  );
};

export { Flex };
