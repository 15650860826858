import React, { createContext, useContext, useState } from 'react';

import { SnackMesage } from '@components/layout';

const SnackbarContext = createContext();

function SnackbarProvider({ children }) {
  const [snack, setSnack] = useState({});
  const [openMsg, setOpenMessage] = useState(false);

  const openMessage = (snackProps) => {
    setSnack(snackProps);
    setOpenMessage(true);
  };

  const closeHandler = () => {
    setOpenMessage(false);
  };

  return (
    <SnackbarContext.Provider
      value={{
        openMessage,
      }}
    >
      {children}
      <SnackMesage openMsg={openMsg} closeHandler={closeHandler} message={snack.message} severity={snack.severity} />
    </SnackbarContext.Provider>
  );
}

export const useSnackbar = () => useContext(SnackbarContext);

export { SnackbarProvider };
