/* eslint-disable */
import * as React from 'react';
import { Helmet } from 'react-helmet';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, darken } from '@mui/material/styles';

import { createTheme, responsiveFontSizes } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#1098E1',
    },
    text: {
      primary: '#3D4458',
    },
    success: {
      main: '#20C745',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        :root {
          --gray-1: #D9E1F2;
          --gray-2: #9BA4B2;
          --gray-3: #5F6F7E;
          --gray-4: #3D4458;
          --gray-5: #8D95AA;
          --blue-1: #1098E1;
          --blue-2: #146EC4;
          --blue-3: #1D3066;
          --warning: #E5F804;
          --info: #079CF0;
          --green-1: #53b227;
        }

        ::-webkit-scrollbar {
          width: 8px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
          background: #f1f1f1;
          border-radius: 8px;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 8px;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
          background: #555;
          background-size: 100px;
        }
      `,
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: true,
      },
      styleOverrides: {
        root: {
          fontSize: '1rem',
          transform: 'translate(14px, -12px) scale(1)',
          color: 'var(--gray-3)',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          '& legend': {
            maxWidth: '100%',
            fontSize: '1rem',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        text: {
          color: 'var(--gray-4)',
        },
        containedPrimary: {
          backgroundColor: 'var(--gray-4)',
          '&:hover': {
            backgroundColor: darken('#3D4458', 0.3),
          },
        },
      },
    },
  },
});
const finalTheme = responsiveFontSizes(theme);

export default function TopLayout(props) {
  return (
    <>
      <Helmet>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <link href="https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap" rel="stylesheet" />
      </Helmet>
      <ThemeProvider theme={finalTheme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        {props.children}
      </ThemeProvider>
    </>
  );
}
