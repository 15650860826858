import { isDate } from 'date-fns';

export * from './useDevice';
export * from './brazilian_states';
export * from './masks';
export * from './messageToSharing';
export * from './HideOnScroll';
export * from './robotobase64';

export const accountTypes = ['Gestor', 'Médico', 'Gestor/ Médico'];

export const complexidades = { baixo: 'Baixo', medio: 'Médio', alto: 'Alto' };

export const contratacao = {
  plantao: 'Plantão',
  sobreaviso: 'Sobreaviso',
  ambulatorio: 'Ambulatório',
};

export const formacoes = {
  experiencia: 'Experiência',
  residencia: 'Residência Médica',
  pos: 'Pós-Graduação',
  especialidade: 'Título de Especialidade',
};

export const containerMaxWidth = 'md';

export const menuH = 81;
export const footerH = 150;

export const supportUrl = (isDesktop) => `https://${isDesktop ? 'web' : 'api'}.whatsapp.com/send?phone=5511988433679`;

export const getCurrency = (value) =>
  value ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value) : '';

export const validateDate = (value, originalValue) => {
  if (isDate(originalValue)) return originalValue;

  const date = originalValue.replace(/^(\d{2})(\d{2})(\d{4})/, '$1/$2/$3');
  const [d, m, y] = date.split('/');

  if (Number(d) > 31) return null;

  if (Number(m) > 12 || Number(m) < 1) return null;

  const newDate = new Date(Number(y), Number(m) - 1, Number(d));

  return newDate;
};

export const prefix = (gender) => {
  let p = 'Dr';

  if (gender === 'feminino') p = `${p}a`;
  if (gender === 'outro') p = `${p}(a)`;

  return `${p}.`;
};

export const listDays = () =>
  Array(30)
    .fill(null)
    .map((_, index) => index + 1);
