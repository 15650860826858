import React, { createContext, useContext } from 'react';

import { doc, setDoc, serverTimestamp, collection, getDocs, deleteDoc, query, where, getDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject, getBlob } from 'firebase/storage';

import { db, storage } from './auth';

const CompanyContext = createContext();

function CompanyProvider({ children }) {
  const registerCompany = async (user, data, blob, id = null) => {
    try {
      const userDataRef = id
        ? doc(db, `users/${user.uid}/companys`, id)
        : doc(collection(db, `users/${user.uid}/companys`));

      let imgUrl = null;
      if (blob) {
        const imgRef = ref(storage, `${user.uid}/company/${userDataRef.id}.jpg`);

        const snap = await uploadBytes(imgRef, blob);

        imgUrl = await getDownloadURL(snap.ref);
      }

      const company = { ...data, image: imgUrl };

      if (!id) {
        company.creation_date = serverTimestamp();
      }

      await setDoc(userDataRef, company, { merge: true });

      return { success: true };
    } catch (err) {
      // eslint-disable-next-line
      console.log(err);
      return { error: true };
    }
  };

  const getCompanys = async (userId) => {
    try {
      const companySnap = await getDocs(collection(db, `users/${userId}/companys`));

      return companySnap.docs;
    } catch (err) {
      return null;
    }
  };

  const getCompany = async (userId, companyId) => {
    try {
      const docSnap = await getDoc(doc(db, `users/${userId}/companys`, companyId));

      if (docSnap.exists()) {
        const data = docSnap.data();

        return data;
      }

      return null;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  const getCompanyImage = async (user, companyId) => {
    try {
      const blob = await getBlob(ref(storage, `${user.uid}/company/${companyId}.jpg`));

      return { blob };
    } catch (err) {
      console.log(err);
      return { error: true };
    }
  };

  const deleteCompany = async (userId, company) => {
    try {
      const companyId = company.id;

      if (company.image) {
        try {
          const fileToDelete = ref(storage, `${userId}/company/${companyId}.jpg`);
          await deleteObject(fileToDelete);
        } catch (err) {
          console.log(err);
        }
      }

      const vagasRef = collection(db, 'vagas');
      const vagasQuery = query(vagasRef, where('uid', '==', userId), where('company.id', '==', companyId));
      const result = await getDocs(vagasQuery);
      if (result.docs.length > 0) {
        await Promise.all(result.docs.map((item) => deleteDoc(doc(db, 'vagas', item.id))));
      }

      await deleteDoc(doc(db, `users/${userId}/companys`, companyId));
      return { success: true };
    } catch (err) {
      console.log(err);
      return { error: true };
    }
  };

  return (
    <CompanyContext.Provider
      value={{
        registerCompany,
        deleteCompany,
        getCompanyImage,
        getCompanys,
        getCompany,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
}

export const useCompany = () => useContext(CompanyContext);

export { CompanyProvider };
