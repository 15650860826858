import React from 'react';

import { AppBar, Toolbar, Box } from '@mui/material';

import Logo from '@assets/newlogo.svg';

export function TopBar() {
  return (
    <AppBar position="relative" sx={{ background: 'transparent', boxShadow: 'none' }}>
      <Toolbar
        sx={{
          background: 'var(--gray-4)',
          clipPath: ['polygon(0 0, 100% 0, 100% 100%, 12% 100%)', 'polygon(0 0, 100% 0, 100% 100%, 5% 100%)'],
          minHeight: { xs: '120px' },
        }}
      >
        <Box
          sx={{
            mx: 'auto',
            '& svg': {
              height: '68px',
            },
          }}
        >
          <Logo />
        </Box>
      </Toolbar>
    </AppBar>
  );
}
