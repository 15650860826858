import { format } from 'date-fns';

export const phoneMask = (phone = '') => {
  if (phone?.length === 10) {
    return phone.replace(/^(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
  }

  if (phone?.length === 11) {
    return phone.replace(/^(\d{2})(\d{1})(\d{4})(\d{4})/, '($1) $2 $3-$4');
  }

  return phone;
};

export const cepMask = (cep) => {
  return cep.replace(/^(\d{5})(\d{3})/, '$1-$2');
};

export const cnpjMask = (cnpj) => {
  if (!cnpj) return '';
  return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
};

export function formateHour(unformatedHour) {
  if (!unformatedHour) return '';

  const { hour, minute } = unformatedHour;

  const d = new Date();
  const day = d.getDay();
  const month = d.getMonth();
  const year = d.getFullYear();

  const dateTime = new Date(year, month, day, hour, minute);

  return format(dateTime, 'HH:mm');
}

export function formatDate(date, formating) {
  if (!date) return '';
  return `${format(date, formating ?? 'dd/MM/yyyy')}`;
}
