import React, { useState, useRef } from 'react';

import { Box, Typography, Button } from '@mui/material';

import AddSharpIcon from '@mui/icons-material/AddSharp';

import loadImage from 'blueimp-load-image';

let AvatarEdit;

if (typeof window !== `undefined`) {
  AvatarEdit = require('react-avatar-edit').default;
}

function Image({ sx, ...props }) {
  return (
    <Box
      {...props}
      sx={{
        width: '35px',
        height: '35px',
        backgroundColor: 'var(--blue-1)',
        color: '#fff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        position: 'absolute',
        bottom: 0,
        right: 0,
        ...sx,
      }}
    />
  );
}

function Avatar({ onPreviewDone }) {
  const inputRef = useRef(null);

  const [tempUrl, setTempUrl] = useState(null);
  const [preview, setPrev] = useState(null);

  const chooseHandler = async (e) => {
    const data = await loadImage(e.target.files[0], { maxWidth: 300, canvas: true, orientation: true });

    setTempUrl(data.image.toDataURL());
  };

  const cropHandler = (prev) => {
    setPrev(prev);
  };

  const closeHandler = () => {
    setTempUrl(null);
    setPrev(null);
    if (inputRef) {
      inputRef.current.value = '';
    }
  };

  const confirmHandler = () => {
    setTempUrl(null);
    onPreviewDone(preview);
    if (inputRef) {
      inputRef.current.value = '';
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '1rem',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        mt: '3rem',
        flexWrap: { xs: 'wrap', sm: 'nowrap' },
      }}
    >
      <Box
        sx={{
          width: '118px',
          height: '118px',
          backgroundColor: 'var(--gray-2)',
          borderRadius: '50%',
          position: 'relative',
          border: 'none',
          order: { xs: 2, sm: 1 },
        }}
      >
        <Box component="img" src={preview} sx={{ width: 1, height: 1, display: preview ? 'block' : 'none' }} />
        <Image
          component="label"
          id="image-label"
          htmlFor="image"
          sx={{ cursor: 'pointer', display: tempUrl ? 'none' : 'flex' }}
        >
          <AddSharpIcon />
        </Image>
        <Box
          ref={inputRef}
          component="input"
          type="file"
          id="image"
          accept="image/png, image/jpeg"
          onChange={chooseHandler}
          sx={{
            width: '1px',
            height: '1px',
            opacity: 0,
          }}
        />
      </Box>
      {tempUrl ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', order: { xs: 1, sm: 2 } }}>
          <Box
            sx={{
              display: 'flex',
              flexGrow: '1',
              justifyContent: 'center',
            }}
          >
            <AvatarEdit width={275} height={195} src={tempUrl} onCrop={cropHandler} onClose={closeHandler} />
          </Box>
          <Box sx={{ width: 1, display: 'flex', justifyContent: 'center' }}>
            <Button variant="contained" color="primary" onClick={confirmHandler}>
              Ok
            </Button>
          </Box>
        </Box>
      ) : (
        <Box>
          <Typography variant="h4">Foto de Perfil</Typography>
          <Typography variant="h5">Carregue sua foto aqui</Typography>
        </Box>
      )}
    </Box>
  );
}

export { Avatar };
