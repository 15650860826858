import React, { useState, useMemo } from 'react';

import { Box, Container } from '@mui/material';

import { LoginLayout } from './LoginLayout';
import { MenuBar } from './MenuBar';
import { Protected } from '../auth';
import { FooterContainer } from './footer';

const Layout = ({ children, ...props }) => {
  const [showMenu, setShowMenu] = useState(true);
  const [newNotifications, setNewNotifications] = useState(false);

  const [isShowFooter, showFooter] = useState(false);

  const hideMenu = (value = false) => {
    setShowMenu(value);
  };

  const setUpdateNotifications = (value) => {
    setNewNotifications(value);
  };

  const Children = useMemo(
    () =>
      React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { hideMenu, setUpdateNotifications, showFooter });
        }

        return child;
      }),
    []
  );

  return (
    <Protected>
      <LoginLayout
        contentProps={{
          noMenu: !showMenu,
          FullContent: () => (
            <>
              <Box sx={{ width: 1, height: 1, overflowY: 'auto', overflowX: 'hidden' }}>
                <Container maxWidth="md" sx={{ height: 1 }}>
                  {Children}
                  {showMenu && (
                    <MenuBar
                      {...props}
                      setUpdateNotifications={setUpdateNotifications}
                      updateNotifications={newNotifications}
                    />
                  )}
                </Container>
              </Box>
              {(showMenu || isShowFooter) && <FooterContainer />}
            </>
          ),
          sx: {
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      ></LoginLayout>
    </Protected>
  );
};

export { Layout };
