import React, { useState, useEffect } from 'react';

import { Autocomplete, TextField } from '@mui/material';

const EspecialidadeAutoComplete = ({ values, errors, changeAutocompleteHandler }) => {
  const [especialidades, setEspecialidades] = useState([]);

  useEffect(() => {
    requestEspecialidades();
  }, []);

  const requestEspecialidades = async () => {
    const esp = await import('@assets/especialidades_medicas.json');

    setEspecialidades(esp.dados);
  };

  if (!especialidades || especialidades.length < 1) return <div></div>;

  return (
    <Autocomplete
      options={especialidades}
      getOptionLabel={(option) => option.DS_ESPECIALIDADE}
      renderInput={(params) => (
        <TextField
          {...params}
          name="especialidade"
          label="Especialidade"
          error={Boolean(errors.especialidade)}
          helperText={errors.especialidade ?? ' '}
        />
      )}
      value={values.especialidade}
      onChange={changeAutocompleteHandler('especialidade')}
    />
  );
};

export { EspecialidadeAutoComplete };
