import React from 'react';

import { Box, Container } from '@mui/material';

import { FullContainer } from './FullContainer';
import Logo from '@assets/newlogo.svg';

const FloatLogoDarkLayout = ({ fullContainerSx = {}, containerSx = {}, logoSx = {}, children }) => {
  return (
    <FullContainer sx={{ bgcolor: 'var(--gray-4)', color: 'white', ...fullContainerSx }}>
      <Container maxWidth="sm" sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', ...containerSx }}>
        <Box component={Logo} sx={{ mb: '4rem', ...logoSx }}></Box>
        {children}
      </Container>
    </FullContainer>
  );
};

export { FloatLogoDarkLayout };
